import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Spanish from 'styles/images/spanish.svg'
import English from 'styles/images/english.jpg'
import Greek from 'styles/images/greece.svg'
import Italy from 'styles/images/italy.svg'
import Portugal from 'styles/images/portugal.svg'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import Config from 'config'
import useUser from 'hooks/useUser'
import Lang from 'lang'
import { useDispatch } from 'react-redux'
import UserOperations from 'redux/user/user-operations'
import { useToasts } from 'react-toast-notifications'
import { Loader } from 'components/ui/molecules/Loader'
import AccountService from 'services/account-service'

const Settings = () => {
  const { t, i18n } = useTranslation()
  const { user } = useUser()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }))

  const [selectedLanguage, setSelectedLanguage] = useState(user.preferences.language.code)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      const result = await AccountService.updatePreferences({
        ...user.preferences,
        language: selectedLanguage
      })
      Lang.setLang(selectedLanguage)
      setUserInfo(result.data)
      await i18n.changeLanguage(selectedLanguage)
      addToast(t('settings.language_updated_successfully'), {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      if (error.response) {
        addToast(t('settings.error_occurred_updating_language'), {
          appearance: 'error',
          autoDismiss: true
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
          {loading && <Loader />}

    <div className='mb-6'>
        <h3 className={`border-b font-medium text-main-${Config.PLATFORM} font-bold mb-4 border-main-${Config.PLATFORM}`}>
          {t('settings.language_section_title')}
        </h3>
        <div className="flex flex-row mb-3">
          <div
            onClick={() => selectedLanguage !== 'es' ? setSelectedLanguage('es') : null}
            className={`w-10 h-10 flex items-center justify-center hover:shadow-inner rounded-full cursor-pointer border-2 bg-white ${selectedLanguage === 'es' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={Spanish} alt="Spanish" style={{ height: 13 }}/>
          </div>

          <div
            onClick={() => selectedLanguage !== 'en' ? setSelectedLanguage('en') : null}
            className={`ml-1 w-10 h-10 flex items-center justify-center hover:shadow-inner rounded-full cursor-pointer border-2 bg-white ${selectedLanguage === 'en' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={English} alt="English" style={{ height: 13 }} />
          </div>

          <div
            onClick={() => selectedLanguage !== 'it' ? setSelectedLanguage('it') : null}
            className={`ml-1 w-10 h-10 flex items-center justify-center hover:shadow-inner rounded-full cursor-pointer border-2 bg-white ${selectedLanguage === 'it' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={Italy} alt="Italy" style={{ height: 13 }} />
          </div>

          <div
            onClick={() => selectedLanguage !== 'pt' ? setSelectedLanguage('pt') : null}
            className={`ml-1 w-10 h-10 flex items-center justify-center hover:shadow-inner rounded-full cursor-pointer border-2 bg-white ${selectedLanguage === 'pt' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={Portugal} alt="Portuguese" style={{ height: 13 }} />
          </div>

          {/* el is Greek language code */}
          <div
            onClick={() => selectedLanguage !== 'el' ? setSelectedLanguage('el') : null}
            className={`ml-1 w-10 h-10 flex items-center justify-center hover:shadow-inner rounded-full cursor-pointer border-2 bg-white ${selectedLanguage === 'el' ? `shadow-inner border-main-${Config.PLATFORM}` : 'shadow-soft-white'}`}
          >
            <img src={Greek} alt="Greek" style={{ height: 13 }} />
          </div>

      </div>
    </div>
    <div className='flex justify-end mt-4'>
        <ButtonMain
            width={'full lg:w-auto'}
            text={t('buttons.save')}
            type={'button'}
            onClick={() => onSubmit()}
        />
    </div>
    </>
  )
}

export default Settings
