import 'tailwindcss/tailwind.css'
import 'index.css'
import 'App.css'
import { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Routes from './components/routes'
import { useSelector, useDispatch } from 'react-redux'
import Scrollbar from 'react-scrollbars-custom'
import { ToastProvider } from 'react-toast-notifications'
import { ToastContainer } from 'components/ui/objects/ToastContainer'
import LoadingOverlay from 'react-loading-overlay'
import Sidebar from './components/layout/Sidebar'
import Header from './components/layout/Header'
import PropTypes from 'prop-types'
import { BasicToast } from 'components/ui/atoms/BasicToast'
import ReactTooltip from 'react-tooltip'
import Config from 'config'
import { Loader } from 'components/ui/molecules/Loader'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/ui/molecules/Modal'
import { ButtonMain } from 'components/ui/atoms/ButtonMain'
import { UserOperations } from 'redux/user'
import { AppOperations } from 'redux/app'
import { BsGear } from 'react-icons/bs'

function App () {
  const scrollBar = useRef(null)
  const location = useLocation()
  const appLoading = useSelector((state) => state.app.loading)
  const appLoadingOverlay = useSelector((state) => state.app.loadingOverlay)
  const authInfo = useSelector((state) => state.auth)
  const [responsiveSidebarVisible, setResponsiveSidebarVisible] = useState(false)
  const { token } = authInfo
  useEffect(() => {
    sessionStorage.setItem('platform_id', Math.random().toString(36).substring(2, 9))
  }, [])
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const logoutUser = () => dispatch(UserOperations.logoutUser())
  const cleanSessionExpired = () => dispatch(AppOperations.setSessionExpired(false))

  useEffect(() => {
    if (scrollBar && scrollBar.current) {
      scrollBar.current.scrollToTop()
    }

    setResponsiveSidebarVisible(false)
    ReactTooltip.hide()
  }, [location.pathname])

  const sessionExpired = useSelector((state) => state.app.session_expired)
  useEffect(() => {
    if (sessionExpired) {
      setShowSessionExpiredModal(true)
    } else {
      setShowSessionExpiredModal(false)
    }
  }, [sessionExpired])

  if (appLoading) {
    return <Loader />
  }

  const handleResponsiveSidebar = () => {
    setResponsiveSidebarVisible(!responsiveSidebarVisible)
  }

  const closeSession = () => {
    cleanSessionExpired()
    logoutUser()
  }

  return (
    <ToastProvider components={{ Toast: BasicToast, ToastContainer }}>
      <Scrollbar
        ref={scrollBar}
        trackYProps={{ className: 'scrollbar_trackY' }}
        thumbYProps={{ className: 'scrollbar_thumbY' }}
        disableTracksWidthCompensation={true}
      >
        {token && (
          <div>
            <Header handleResponsiveSidebar={handleResponsiveSidebar} />
            <Sidebar responsiveSidebarVisible={responsiveSidebarVisible} handleResponsiveSidebar={handleResponsiveSidebar} />
            {showSessionExpiredModal && (
              <Modal
              showModal={showSessionExpiredModal}
              showCloseModal={false}
              onClose={closeSession}
              paddingTop="6"
              paddingBottom="4"
              fixedWidth={'w-1/2'}
            >
              <h3 className={'font-medium mr-4 text-main'}>{t('session_expired_title')}</h3>
              <div className='my-4 text-sm'>
                {t('session_expired_description')}
              </div>
                  <div className={'flex justify-end mt-4 border-t pt-2 border-separator'}>
                    <ButtonMain
                      type="submit"
                      text={t('close_session')}
                      onClick={closeSession}
                    />
                  </div>
            </Modal>
            )}
            <main id="app-content-wrapper" className='pl-4 pr-4 sm:pl-24 lg:pl-56'>
              <section className='w-full h-full pr-0 py-6'>
                <Routes />
                {/* <div className='flex flex-col items-center justify-center h-full'>
                  <BsGear className='text-gray-700 text-8xl mb-4' />
                  <h2>{t('maintenance_title')}</h2>
                  <p className='text-gray-600 mt-2'>{t('maintenance_description')}</p>
                </div> */}
              </section>
            </main>

            {appLoadingOverlay && appLoadingOverlay.state && (
              <div
                style={{
                  position: 'fixed',
                  zIndex: 1500,
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)'
                }}
              >
                <LoadingOverlay active={true} spinner={true} text={appLoadingOverlay.message} />
              </div>
            )}
          </div>
        )}

        {!token && <Routes />}

        <ReactTooltip
          effect="solid"
          backgroundColor="white"
          className={`shadow-soft-white text-main-${Config.PLATFORM} opacity-100 font-bold`}
        />
      </Scrollbar>
    </ToastProvider>
  )
}

App.propTypes = {
  window: PropTypes.func
}

export default App
